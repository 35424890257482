// @flow
import React from 'react';
import { withPrefix, Link, useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import styles from './Author.module.scss';

type Props = {
  author: {
    name: string,
    bio: string,
    photo: string,
  },
  isIndex: ?boolean,
};

const Author = ({ author, isIndex }: Props) => {
  const { image } = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "profilePic.png" }) {
        sharp: childImageSharp {
          fixed(width: 75) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `);

  return (
    <div className={styles['author']}>
      <Link to='/'>
        <Img
          className={styles['author__photo']}
          alt={author.name}
          fixed={image.sharp.fixed}
        />
      </Link>

      {isIndex ? (
        <h1 className={styles['author__title']}>
          <Link className={styles['author__title-link']} to='/'>
            {author.name}
          </Link>
        </h1>
      ) : (
        <h2 className={styles['author__title']}>
          <Link className={styles['author__title-link']} to='/'>
            {author.name}
          </Link>
        </h2>
      )}
      <p className={styles['author__subtitle']}>{author.bio}</p>
    </div>
  );
};

export default Author;
